import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Dimitris F. | Portfolio', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Dimitris Frangiadakis Portfolio Website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, I am',
  name: 'Dimitris Frangiadakis',
  subtitle: 'A fullstack software engineer',
  cta: 'See more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'A software engineer with almost 8 years of professional experience. Specializing in Python and PHP. Worked a lot with React. Passionate for Go.',
  paragraphTwo:
    'Have contributed to high-traffic projects as part of a 20-person engineering team, serving over 3M users.',
  paragraphThree:
    'Have also successfully managed and developed smaller projects independently, catering to over ' +
    '4k users. My expertise spans new feature development as well as legacy systems maintenance.' +
    ' Α polyglot philosophy and strong foundations in web application best practices and design patterns.',
  resume: 'https://analytics.frangiadakis.com/cv', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'tesla-logo.png',
    title: 'Tesla Management Panel',
    info:
      'Control Panel for allowing passengers of a Tesla car to interact with the car, while maintaining granular access control.',
    info2:
      'The permissions & UI service is made with Laravel & React, the car communication service using FastAPI.',
    url: 'https://analytics.frangiadakis.com/tesla_demo', // if no repo, the button will not show up
    repo: 'https://analytics.frangiadakis.com/tesla_github', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'translations-logo.png',
    title: 'OpenAI Translations',
    info: 'PoC app using the OpenAI API.',
    info2:
      'Translates and summarizes content to other languages. Implemented 2 BEs (Node.js, Symfony) and used React for the FE.',
    url: 'https://analytics.frangiadakis.com/gpt_translate', // if no repo, the button will not show up
    repo: 'https://analytics.frangiadakis.com/gh_gpt_trans', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'iconsdkphp.png',
    title: 'ICON Blockchain SDKs',
    info: 'An SDK to communicate with the ICON blockchain, built for PHP.',
    info2: 'Also implemented for Go and Rust.',
    url: null, // if no repo, the button will not show up
    repo: 'https://analytics.frangiadakis.com/github_icon_sdk', // if no repo name, the button will not show up
    reponame: 'PHP SDK', // if no repo name, the default text will show
    repo2: 'https://analytics.frangiadakis.com/github_sdk_go', // if no repo, the button will not show up
    repo2name: 'Go SDK', // if no repo name, the button will not show up
    repo3: 'https://analytics.frangiadakis.com/github_sdk_go', // if no repo, the button will not show up
    repo3name: 'Rust SDK', // if no repo name, the button will not show up
  },
  {
    id: nanoid(),
    img: 'agm-logo.jpg',
    title: 'AGM Thessaloniki - Control Panel',
    info: 'Event management software.',
    info2:
      'Uses CAS server authentication, syncing with external APIs & card payment processing. Built with Laravel',
    url: 'https://analytics.frangiadakis.com/agm', // if no repo, the button will not show up
    repo: 'https://analytics.frangiadakis.com/github_agm', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to get in touch?',
  btn: '',
  email: 'dimitris@frangiadakis.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://analytics.frangiadakis.com/github',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://analytics.frangiadakis.com/linkedin',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://analytics.frangiadakis.com/twitter',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://analytics.frangiadakis.com/facebook',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
